/***
*
*   USER
*   shows the current user 
*   if user belongs to more than one account they can switch accounts here
*
**********/

import { AuthContext, Button, HoverNav, Icon, ViewContext } from 'components/lib';
import React, { useContext } from 'react';
import Style from './user.tailwind.js';

export function User(props) {

  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const accountName = authContext.user?.accounts?.find(x => x.id === authContext.user?.account_id)?.name;

  return (
    <div className={Style.user}>

      <div className={Style.name}>
        <div className={Style.avatar}><Icon image="user" ></Icon></div>
        <div className={Style.nameText}>{authContext.user?.name}</div>
      </div>

      {authContext.user?.accounts?.length > 1 &&
        <HoverNav
          dark
          icon='user'
          label={accountName}
          className={Style.hoverNav}
          align='right' >
          {authContext.user.accounts.map(account => {

            return (
              <Button
                key={account.id}
                text={account.name}
                action={() => {

                  viewContext.setLoading(true);
                  authContext.switchAccount(account.id);

                }}
              />
            );
          }
          )}
        </HoverNav>
      }
    </div>
  )
}