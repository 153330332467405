import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ViewContext, useAPI } from "components/lib";

/**
 * TODO: cache bots title so it doesn't 
 * have to reload on each page change
 * and can share between components
 **/

// TODO: load bot from shared bot context instead of API

export function useBotsTitle({ idField } = {}) {
  const [title, setTitle] = useState();
  const { [`${idField || 'id'}`]: botId } = useParams();
  const context = useContext(ViewContext);

  // Load the Bot and set the title
  const { data: bot } = useAPI(`/api/bot/${botId}`);

  // Set the title on load success
  useEffect(() => {
    if (!bot) return;
    setTitle(bot.name);
  }, [bot, bot?.name]);

  // Bot loaded, set the title
  useEffect(() => {

    if (String(title).toLowerCase() === 'undefined')
      return;

    if (!!title && context?.setTitle) {
      context.setTitle(title);
    }
  }, [context, title])

  return { title, setTitle };

}