/***
*
*   useAPI hook
*   Interact with API calls – handle errors, return loading state and data
*
*   PROPS
*   url: endpoint url
*   method: get, post, put etc.. (default: get)
*
**********/

import Axios from 'axios';
import { ViewContext } from 'components/lib';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

export function useAPI(url, method = 'GET', autoFetch) {

  // wrap in useRef to prevent triggering useEffect multiple times  
  const context = useRef(useContext(ViewContext));
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // default GET requests to fetch automatically
  autoFetch = autoFetch === undefined ? (String(method).toLowerCase() === 'get') : autoFetch;

  const fetch = useCallback(async (body, onSuccess, options) => {
    try {

      if (!url && !options?.url) {

        setData(null);
        setLoading(false);
        return false;

      }

      setLoading(true);
      const res = await Axios({

        url: options?.url || url,
        method: method || 'GET',
        data: body || undefined

      })

      setData(res.data.data);
      setLoading(false);

      // success callback
      if (onSuccess)
        onSuccess(res.data.data);

      return res.data.data;

    }
    catch (err) {

      context?.current &&
        context.current.handleError(err);

    }
  }, [url, method, context]);

  useEffect(() => {

    // Fetch automatically on mount
    if (autoFetch)
      fetch()

  }, [fetch, autoFetch, method]);

  return { data, loading, fetch }

}
