/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import { Button, Features, Hero, Row, config } from 'components/lib';
import React, { Fragment } from 'react';
import ImgDashboard from './images/docsly_screenshot_1.png';

const { googleBrand } = config;


export function Home(props) {

  return (
    <Fragment>

      <Hero
        title={'AI Chat Bots from your Docs'}

        tagline={<>Use your <strong>Google Drive <sup>&trade;</sup></strong>{/* docs, websites, and videos*/} to turn documents into AI-powered chat bots that can answer any question about your data</>}
        image={ImgDashboard}
      />

      <Row title='Features' color='white'>
        <Features />
      </Row>

      {/* <Row color='brand'>
        <Testimonial
          text='Gravity saved me weeks of development time and thousands of dollars by eliminating the need to hire a developer to built the necessary infrastructure for my app. I had a beautiful product with billing capabilities in a matter of minutes!'
          author='Kyle Gawley, Gravity Founder'
          image={ImgKyleGawley}
        />
      </Row> */}

      <Row title='Build your first chat bot for free' align='center'>
        <Button goto='/signup' text='Sign Up Now' className='inline-block' big />
      </Row>
    </Fragment>
  );
}
