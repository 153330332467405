/***
*
*   BADGE
*   Text badge with background color
*
*   PROPS
*   text: string to be displayed
*   color: gray/blue/red/green/orange (default: gray)
*
**********/

import { ClassHelper } from 'components/lib';
import Style from './badge.tailwind.js';

export function Badge(props) {

  const badgeStyle = ClassHelper(Style, {

    [props.color]: !!props.color,
    gray: !props.color,
    className: props.className

  })

  return (

    <span className={badgeStyle}>
      {props.text}
    </span>

  );
}