import { ClassHelper, Loader } from 'components/lib';
import { arrayOf, object, shape, string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatContext } from '../context';
import { ChatMessage } from '../message/message';
import Style from './messages.tailwind';

export function ChatMessages({ messages = [], className }) {
  const [state, setState] = useState();
  const { loading } = useContext(ChatContext);

  const containerRef = useRef(null);

  const scrollBottom = () => {
    const container = containerRef.current;
    const top = container.scrollHeight - container.clientHeight + 300;
    container.scrollTop = top;
  }

  useEffect(() => {
    scrollBottom();
    // Delay the scroll to ensure the content is rendered
    const delayScroll = setTimeout(() => {
      scrollBottom();
    }, 100);

    // Clean up the timeout
    return () => clearTimeout(delayScroll);
  }, []);

  useEffect(() => {
    // scroll bottom when messages changes
    scrollBottom();
  }, [messages]);

  return (
    <div
      className={ClassHelper(Style, {
        className: className
      })}
      ref={containerRef}
    >
      {messages.map(({ sender, text }, index) => {
        return <ChatMessage
          key={index}
          sender={sender}
          text={text}
        />;
      })}
      {loading &&
        <ChatMessage
          //  sender="system" 
          bottom
          text={<><Loader /></>}
        />
      }
    </div>
  );
}

ChatMessages.propTypes = {
  messages: arrayOf(
    shape({
      sender: string,
      message: string,
    }),
  ),
  className: string,
  ref: object
};
