const Style = {

  table: `min-w-full border-b-separate md:mt-[-1em]

  `,
  table_wrapper: `
  -ml-2 -mr-2
  md:-ml-4 md:-mr-4
  `,
  inputs: 'mb-5 md:flex',
  search: 'flex mb-2 md:mb-0 flex-1',
  loading: 'relative py-8',
  badge: 'ml-0',
  empty: 'py-12 text-center',
  thead: 'hidden font-semibold md:table-header-group',
  th_actions: 'text-right',
  // th_select: '!pl-px w-4',

  th: `text-left outline-0 capitalize py-5 px-5 border-b border-dotted border-slate-100 
  last:pr-4 
  `,
  sort: `relative cursor-pointer after:absolute after:right-0 after:top-1/2 after:mt-[-2px]
    after:w-3 after:h-3 after:opacity-50 after:-translate-y-1/2 after:bg-contain`,

  tr: `
  
  `,
  tr_clickable: 'cursor-pointer active:bg-slate-200 hover:bg-slate-100',
  tr_selected: 'bg-slate-100',

  asc: `after:bg-[url('components/table/icons/ico-sort-asc.svg')]`,
  desc: `after:bg-[url('components/table/icons/ico-sort-dsc.svg')]`,

  cell: `float-left mt-3 md:mb-[-0.6em] w-full
    px-2 md:px-5
    last:pr-4
    md:float-none md:w-auto md:mt-0 md:mb-0 md:p-5 md:border-b md:border-solid md:border-slate-100`,

  cell_empty: 'hidden md:table-cell',

  bulk_actions: 'flex mr-0',
  bulk_actions_button: 'ml-2 first:ml-0 flex-1 md:first:ml-2 md:flex-initial',

  row_actions: `text-left float-left mt-4 w-full clear-left pb-4 whitespace-nowrap
    md:float-none md:clear-none md:w-auto md:text-right
    border-b border-slate-100 border-solid
    last:pr-8
    `,

  row_actions_button: 'inline-block whitespace-nowrap -top-1 mr-3 bg-transparent last:mr-0',

  select: `pt-4 pl-8 align-top md:align-middle float-none !w-8 border-b border-slate-100 border-solid
    !md:px-[2px]
  `,
  select_all: 'float-left pl-[2px] pb-3 border-b border-slate-100 border-dotted w-full md:hidden',

}

export default Style;