import { Chat, Loader, useAPI } from 'components/lib';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBotsTitle } from './hooks/useBotsTitle';


export function BotsChat(props) {
  const { id: botId } = useParams();
  const { data: bot, loading } = useAPI(`/api/bot/${botId}`);
  const [answers, setAnswers] = useState([]);
  const [messages, setMessages] = useState([]);

  const {
    data: question,
    loading: creatingQuestion,
    fetch: createQuestion,
  } = useAPI(`/api/bot/${botId}/question`, 'POST');
  const [submitting, setSubmitting] = useState(false);

  useBotsTitle();

  if (!bot)
    return <Loader />

  const starterMessages = [
    {
      sender: 'system',
      text: `Hi! My name is ${bot.name}.\n\n${bot.description}`,
    },
  ]

  if (bot)
    return (
      <>
        <Chat botId={botId} messages={starterMessages} ></Chat>
      </>
    );
}
