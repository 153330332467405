const Style = {

  plans: 'flex flex-row flex-wrap items-stretch justify-center gap-4 mt-10 md:mt-0',
  plan: 'flex flex-col w-full max-w-80 md:w-60 p-6 last-of-type:mb-6',
  name: 'absolute font-semibold text-center font-sm text-brand-500 uppercase md:relative md:text-slate-500',
  price: 'absolute text-brand-500 font-bold text-2xl right-4 top-0 md:relative md:text-3xl md:top-auto md:right-auto md:mt-4',
  features: `columns-2 ml-0 mt-10 mb-5 md:columns-auto md:mt-4
  `,
  interval: 'text-xs ml-2 font-normal text-slate-500',
}

export default Style;