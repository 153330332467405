import { ClassHelper, Form, Icon } from 'components/lib';
import { string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { ChatContext } from '../context';
import Style from './form.tailwind';

export function ChatForm({ className }) {

  const [state, setState] = useState();
  const { messages, addMessage, botId, loading, addUserQuestion } = useContext(ChatContext);

  useEffect(() => {


  }, []);

  const onSubmit = async (data, resetForm) => {
    // TODO: move api logic into Context in promise Context.addMessage
    await addUserQuestion(data.question);
    resetForm();
  };

  return (
    <div className={ClassHelper(Style, {
      wrapper: true,
      className: className
    })}>
      <div className={Style.innerWrapper}>
        <Form
          name='chat-prompt-form'
          inputs={{
            question: {
              type: 'textarea',
              // required: true,
              autoFocus: true,
              hideSuccessIcon: true,
              className: Style.textarea,
              wrapperClassName: Style.textareaWrapper,
              minRows: 2,
              maxRows: 8,
              rows: 1,
              value: ''
            },
          }}
          buttonText={<><Icon image={["fas", "paper-plane"]} pack="fontawesome" /></>}
          buttonProps={{
            className: Style.button,
            position: "absolute",
            loading: false, // suppress default loading animation
          }}
          onSubmit={onSubmit}
          resetOnSubmit
          focusInput="question"
          // disabled={true}
          // loading={true}
          fullWidth={false}
        ></Form>
      </div>
    </div >
  );
}

ChatForm.propTypes = {
  className: string,
}