const Style = {

  base: 'flex flex-col p-6 x-1 mb-6 bg-white rounded last-of-type:mb-0',
  transparent: '!bg-transparent',
  header: 'flex flex-row mb-4 pb-4 border-slate-100 border-b',
  title: 'flex-grow m-0 text-lg font-semibold',
  subtitle: 'block text-sm font-normal',
  actions: 'flex-shrink-0 flex flex-row space-x-2',
  body: 'flex flex-col relative flex-grow',
  footer: 'mt-5 border-t border-gray-900/5 pt-6 px-0 pb-0',
  center: 'mx-auto',
  loading: 'h-60',
  shadow: 'drop-shadow-sm',
  noPadding: '!p-0',
  restrictWidth: 'max-w-md',
  last: 'mb-0'

}

export default Style