import {
  Animate,
  BotsNav,
  Button,
  Card,
  Loader,
  Table,
  useAPI,
  utility
} from 'components/lib';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBotsTitle } from './hooks/useBotsTitle';

export function BotsTraining(props) {

  const { id: botId } = useParams();
  const [trainings, setTrainings] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const { fetch: getTranings, data: trainingsData = [], loading } = useAPI(`/api/bot/${botId}/training`);
  const { fetch: createTraining, creating } = useAPI(
    `/api/bot/${botId}/training`,
    'POST');

  const retryCount = useRef(0);
  const intervalId = useRef(null);

  const resetRetries = () => {
    retryCount.current = 0;
  }

  // Dynamic page title
  useBotsTitle();

  // Set the UI data after the API data is loaded
  useEffect(() => {
    if (trainingsData?.length > 0) {
      setTrainings(trainingsData.map(t => ({
        created_at_formatted: utility.formatDate(new Date(t.created_at)),
        elapsed: t.processed_at
          ? utility.timeElapsedString(t.processed_at, t.completed_at)
          : '-',
        ...t,
      })));
    }
    setFirstLoad(false);
  }, [trainingsData])

  const handleRefreshTrainings = () => {
    resetRetries();
    if (getTranings)
      getTranings();
  }
  const handleCreateTraining = () => {
    resetRetries();
    createTraining({}, () => {

      // refetch trainings after creation
      getTranings();
    })
  }

  // auto refresh trainings
  useEffect(() => {
    const delay = 1000;
    const maxMinutes = 1;
    const maxRetries = (maxMinutes * 60 * 1000) / delay;
    const active = trainings.find(t => ['active', 'waiting'].includes(t.job_state));


    // if active record, refresh every 500ms
    if (active) {
      intervalId.current = setInterval(() => {

        // limit max retries
        if (retryCount.current > maxRetries) {
          if (intervalId.current)
            clearInterval(intervalId.current);
          return;
        }

        // refresh trainings
        if (getTranings)
          getTranings();

        // increment retry count
        retryCount.current++;
      }, delay);
    } else {
      if (intervalId.current)
        clearInterval(intervalId.current);
    }
    return () => {
      if (intervalId.current)
        clearInterval(intervalId.current);
    }
  }, [trainings, getTranings]);

  const retryUrl = `/api/bot/${botId}/training/UNDEFINED/retry`;
  const { fetch: retryTraining } = useAPI(retryUrl, 'POST');
  const handleRetryTraining = useCallback(async (training) => {
    const trainingId = training.id;
    const retryUrl = `/api/bot/${botId}/training/${trainingId}/retry`;
    await retryTraining({}, () => {
      getTranings();
    }, { url: retryUrl });

  }, [botId, getTranings, retryTraining]);

  return (

    <Fragment>

      <BotsNav />

      {(loading && firstLoad)
        ? <Loader />
        : <Animate>

          <Card
            title="Train"
            subtitle="Retrain your bot after adding or updating docs"
            actions={[
              <Button key="refresh" action={handleRefreshTrainings} outline disabled={loading} loading={loading} small>Refresh</Button>,
              <Button key="train" action={handleCreateTraining} disabled={creating} loading={creating} small>Train</Button>,
            ]}
          >
            <Table
              data={trainings}
              sort={false}
              header={[
                // { name: 'icon', title: '', sort: false, className: "w-14 max-w-14 !p-0" },
                // { name: 'name', title: 'Name', sort: true },
                // { name: 'type', title: 'Type', sort: true },
                // { name: 'id', title: 'ID', sort: false },
                { name: 'created_at_formatted', title: 'Trained' },
                { name: 'elapsed', title: 'Time' },
                { name: 'doc_count', title: 'Docs' },
                { name: 'job_state', title: 'Status' },
                { name: 'job_progress', title: 'Progress' },
              ]}
              badge={{
                col: 'job_state', color: 'blue', condition: [

                  { value: 'waiting', color: 'gray' },
                  { value: 'active', color: 'blue' },
                  { value: 'completed', color: 'green' },
                  { value: 'failed', color: 'red' }

                ]
              }}
              loading={false}
              // show={['icon', 'name', 'type']}
              show={['created_at_formatted', 'elapsed', 'doc_count', 'job_state', 'job_progress']}
              formatters={{
                job_progress: (value) => {
                  if (!value) return '-';
                  // if value is number, show as percentage
                  if (typeof value === 'number')
                    return `${value}%`;
                  return value;
                }
              }}
              actions={{
                // delete: deleteDoc,
                custom: [{ icon: 'rotate-ccw', title: "Retry", action: handleRetryTraining, condition: { col: 'job_state', value: ['failed', 'active', 'waiting'] } }],

              }}
              bulkActions={{
                // delete: deleteDoc,
              }}

            ></Table>
          </Card>

        </Animate>
      }
    </Fragment >

  );
}