/***
*
*   CHECKLIST
*   Ltems with X or ✓
*
*   PROPS
*   items: array of objects containing keys: name (string) and checked (bool)
*
**********/

import { ClassHelper } from 'components/lib';
import Style from './checklist.tailwind.js';

export function CheckList(props) {

  if (!props.items)
    return <div>No items in list</div>

  const checklistStyle = ClassHelper(Style, {

    checklist: true,
    className: props.className,

  });


  const displayName = (feature) => {
    const features = {
      "create_bot": { name: "Custom AI Chat Bots" },
      "publish_public": { name: "Publish Public Chat Bots" },
      "website_embed": { name: "Website Embed Snippet" },
      "automatic_training": { name: "Automatic Re-Training" },
      "api_access": { name: "API Access" },
      "team_members": { name: "Invite Team Members" },
      "publish_private": { name: "Publish Private Chat Bots" },
    };
    const name = features[feature?.key]?.name || feature.name || feature.key;
    return name;
  }

  return (
    <ul className={checklistStyle}>
      {props.items.map((item, index) => {

        item.style = ClassHelper(Style, {

          item: true,
          checkGreen: item.checked !== false && !props.color,
          checkWhite: item.checked !== false && props.color === 'white',
          cross: item.checked === false && !item?.labelOnly && !item.hideCross,
          interactive: props.interactive

        })

        return (
          <li
            onClick={item.onClick}
            className={item.style}
            key={index}>
            {displayName(item)}
          </li>
        );

      })}
    </ul>
  );
}