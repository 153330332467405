/***
*
*   CARD
*   Universal container for grouping UI components together
*
*   PROPS
*   title: title string (optional)
*   loading: boolean to toggle the loading animation (optional)
*   shadow: apply a box shadow
*   center: center align the card
*   noPadding: remove the padding
*   restrictWidth: restrict the width of the card on large screens
*
**********/

import { ClassHelper, Loader } from 'components/lib';
import { bool, node, oneOfType, string } from 'prop-types';
import React from 'react';
import Style from './card.tailwind.js';

export function Card(props) {

  const cardStyle = ClassHelper(Style, {

    base: true,
    shadow: props.shadow,
    center: props.center,
    noPadding: props.noPadding,
    loading: props.loading,
    last: props.last,
    restrictWidth: props.restrictWidth,
    transparent: props.transparent,
    className: props.className,

  });

  return (
    <section className={cardStyle}>

      {(props.title || props.subtitle || props.actions) &&
        <header className={Style.header}>
          <h1 className={Style.title}>{props.title}&nbsp;
            {props.subtitle && <small className={Style.subtitle}>{props.subtitle}</small>}</h1>

          {props.actions &&
            <div className={Style.actions}>
              {props.actions}
            </div>
          }
        </header>
      }

      <section className={Style.body}>
        {props.loading ?
          <Loader /> :
          props.children
        }
      </section>

      {props.footer &&
        <footer className={Style.footer}>
          {props.footer}
        </footer>
      }
    </section>
  );
}

Card.propTypes = {
  actions: node,
  center: bool,
  children: node,
  className: string,
  footer: oneOfType([string, node]),
  last: bool,
  loading: bool,
  noPadding: bool,
  restrictWidth: bool,
  shadow: bool,
  subtitle: oneOfType([string, node]),
  title: oneOfType([string, node]),
  transparent: bool,

}