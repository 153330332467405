/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: select plan
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Error, Link, Checkbox, Header } from 'components/lib';
import { Input } from 'postcss';



export function Signup(props) {

  const context = useContext(AuthContext);
  const [consent, setConsent] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const onChecked = (index, checked, option) => {
    setDirty(true);
    if (!checked) {
      setConsent(true);
    } else {
      setConsent(false);
    }
  }


  // const disabled = !consent;
  const disabled = false;

  const networkOnClick = React.useCallback((network) => {
    // @ts-ignore
    setDirty(true);
    if (!consent)
      return false;
  }, [consent]);

  return (
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={false} restrictWidth center>

          <div className='mb-6'>
            <Header title="How we use your data" className="mb-4 pb-0"></Header>
            <p className='mb-4'>Docsly uses content and metadata from your documents to train chat bots that can answer questions about your files, including files imported from <strong>Google Drive <sup>&trade;</sup></strong>. We follow strict practices for data security and encryption. More details can be found in our <Link url="/privacy" target="_blank">privacy policy</Link>.</p>
            <div className=''>
              <Checkbox
                name="consent"
                checked={consent}
                callback={onChecked}
                wrapperClassName=""
                option={<>I agree to Docsly's <Link url="/privacy" target="_blank">privacy policy</Link> and <Link url="/terms" target="_blank">terms & conditions</Link></>}
              />

              {dirty && !consent &&
                <Error message={"Please agree to sign up for Docsly"} />}

            </div>
          </div>
          <SocialSignin
            network={['google']}
            showOr={false}
            signup
            disabled={disabled}
            onClick={networkOnClick}
          />
          {/* 
          <Form
            inputs={{
              name: {
                label: 'First Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name'
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url='/api/account'
            method='POST'
            buttonText='Create Account'
            callback={context.signin} /> */}

          <div className='mt-4 text-center'>
            Already registered? <Link url='/signin' text='Sign In' />
          </div>

        </Card>
      </Row>
    </Animate>
  );
}
