/***
*
*   LOADER
*   Infinite spinning animation for loading states
*
**********/

import { ClassHelper, Icon } from 'components/lib';
import React from 'react';
import Style from './loader.tailwind.js';

export function Loader(props) {

  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={loaderStyle}>
      {/* <img src={Orbit} className={Style.orbit} alt='Orbit Spinner' /> */}
      <Icon image={["fas", "spinner"]} pack="fontawesome" className={Style.spinner} />
    </div>
  );
}