const Style = {
  base: '',
  wrapper: 'flex flex-col w-full mx-0 justify-center flex-grow py-4 md:py-8 px-6 relative bg-white rounded-xl shadow-xs',
  wrapperDark: 'dark:border-gray-900/50 dark:text-white dark:bg-gray-700 dark:shadow-xs',
  innerWrapper: "w-full max-w-2xl relative mx-auto border border-black/10 shadow-xl",
  textarea: `w-full resize-none border-0 bg-transparent
  m-0 pl-4 pr-10 md:pr-12
  focus:ring-0 focus:border-0 focus-visible:ring-0 `,
  textareaDark: 'dark:bg-transparent',
  textareaWrapper: `!mb-0`,
  button: `absolute flex
  !px-2 md:px-7
  py-[.5rem] md:p-2
  w-8 h-8 md:w-8 md:h-8
  right-2 bottom-[0.5rem] md:right-2 md:bottom-[0.5rem] 
  rounded-md disabled:text-gray-400 enabled:bg-brand-500 enabled:hover:bg-brand-700 text-white transition-colors disabled:opacity-40
  `,
  buttonDark: 'dark:hover:bg-gray-900 dark:disabled:hover:bg-transparent',
}

export default Style;