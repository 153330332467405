import React, { useContext } from 'react';
import { Checkbox, SocialSignin, ViewContext, Link, Error, utility, AuthContext } from 'components/lib';
// import { useConsent } from './useConsent';

import googleDriveIcon from '../social/images/logo_drive_2020q4_color_2x_web_64dp.png';

export function useGoogleConsentModal() {

  const context = useContext(ViewContext);
  const auth = useContext(AuthContext);
  // const { consent, onChecked, dirty, validateConsent } = useConsent();

  function openModal() {
    context.modal.show({
      title: <>Authorize Import from Google Drive <sup>&trade;</sup></>,
      // form: {
      //   email: {
      //     label: 'Email',
      //     type: 'text',
      //     required: true,
      //   },
      //   permission: {
      //     label: 'Permission',
      //     type: 'select',
      //     default: 'user',
      //     options: permissions?.data?.list?.filter(x => x.value !== 'owner')
      //   },
      // },
      // buttonText: 'Authorize Google Drive',
      text: <>
        <div>
          <p className='mb-4'>Docsly uses content and metadata from your documents to train chat bots that can answer questions about your files.<br /><br />More details can be found in our <Link url="/privacy" target="_blank">privacy policy</Link>.</p>

        </div>
        <SocialSignin
          network={['google']}
          showOr={false}
          text="Authorize Google Drive"
          icon={googleDriveIcon}
          iconPack="img"
          iconSize={24}
          iconClassName="bg-white p-[10px]"
          redirect={utility.getPath()}
          className={"mt-8 mb-0"}
          email={auth.user?.email}
        // onClick={validateConsent}
        />
      </>,
      // url: '/api/invite',
      // method: 'POST',
    });
  }

  return { openModal }
}
