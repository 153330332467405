import { ClassHelper } from 'components/class';
import { Link } from 'components/lib';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Style from './source.tailwind';

export function ChatMessageSource({ id, name, url, type, mimeType, iconUrl }) {

  const [state, setState] = useState();

  useEffect(() => {
  }, []);

  return (
    <div className={ClassHelper(Style, {})}>
      <Link href={url || '#'} target="_blank" rel="noopener noreferrer" className={Style.link}>
        <img src={iconUrl} className={Style.icon} alt={`${type} icon`} /><span className={Style.name}>{name || 'source'}</span>
      </Link>
    </div>);
}

ChatMessageSource.propTypes = {
  id: string,
  name: string,
  url: string,
  type: string,
  mimeType: string,
  iconUrl: string,
};
