import { useCallback } from 'react';
import useDrivePicker from 'react-google-drive-picker'
import { useProviderScopes } from './useProviderScopes';
import { useGoogleConsentModal } from './useGoogleConsentModal';
import { useAPI } from './api';

const Settings = require('settings.json');

const REQUIRED_SCOPES = ["https://www.googleapis.com/auth/drive.readonly"];

export function useGoogleDrivePicker(
  // token = null,
  onPicked = (_) => { },
  onCancel = (_) => { },
) {

  // credentials and token data
  const { data: tokenData, loading: loadingToken } = useAPI('/api/user/token/google');
  const { scopes, loading: loadingScopes } = useProviderScopes('google');
  const { id: tokenId, access: token } = tokenData || {};

  // pickers/modals
  const [openPicker /* , authResponse */] = useDrivePicker();
  const { openModal: openConsentModal } = useGoogleConsentModal();



  const openGoogleDrivePicker = useCallback(() => {

    openPicker({
      clientId: Settings[process.env.NODE_ENV].google.clientId,
      developerKey: Settings[process.env.NODE_ENV].google.developerKey,
      // viewId: "FOLDERS", // "DOCS",
      viewId: "DOCS",
      viewMimeTypes: [
        "application/vnd.google-apps.folder",
        "application/vnd.google-apps.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
        "text/plain",
      ].join(','),
      // token: token, // pass oauth token in case you already have one
      token: token,
      supportDrives: true,
      multiselect: true,
      showUploadView: false,
      showUploadFolders: false,
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        if (data.action === 'cancel') {
          onCancel && onCancel(data);
        }
        if (data?.action === 'picked') {
          onPicked && onPicked(data);
        }
      },
    })
  }, [onCancel, onPicked, openPicker, token]);

  const openPickerOrConsent = useCallback(async (e) => {
    if (loadingScopes) {
      return;
    }
    if (hasRequiredScopes(scopes, REQUIRED_SCOPES)) {
      return openGoogleDrivePicker();
    }
    return openConsentModal();
  }, [loadingScopes, openConsentModal, openGoogleDrivePicker, scopes]);

  const enabled = !loadingScopes && !loadingToken;

  return {
    openPicker: openPickerOrConsent,
    enabled
  };
}

function hasRequiredScopes(scopes, requiredScopes) {
  return requiredScopes.every(scope => scopes.includes(scope));
}