/***
*
*   API Keys List
*   List & manage the API keys
*
**********/

import {
  Animate,
  // BlankSlateMessage,
  Button, Card,
  CreateChatBot,
  Link,
  Loader,
  Table,
  ViewContext,
  config,
  useAPI,
  useCreateBot,
  utility
} from 'components/lib';
import React, {
  Fragment, useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

const Settings = require('settings.json');

export function BotsList(props) {

  const [bots, setBots] = useState([]);
  const { data, loading } = useAPI('/api/bot');
  const context = useContext(ViewContext);

  // after fetching, set the bots for UI to render
  useEffect(() => {
    if (data && Array.isArray(data)) {
      setBots(data);
    }
  }, [data])


  const onNewChatBot = useCallback(() => {
    context.modal.show({
      title: <>Create a new Chat Bot</>,
      text: <div className="flex flex-col content-stretch items-start">
        <CreateChatBot
          // @ts-ignore
          title={false}
          onSuccess={() => {
            context.modal.hide();
          }} /></div>,

    });
  }, [context]);


  return (
    <Fragment>

      {/* <AccountNav /> */}

      {(loading || !Array.isArray(bots)) ?
        <Loader /> :
        <Fragment>

          {bots?.length ?
            <Animate>

              {/* <TitleRow>
                <Button small text='Create Bot'
                  action={openPicker}
                  loading={creating}
                  icon={['fab', 'google-drive']}
                  iconPack="fontawesome"
                  outline
                  disabled={creating || loadingToken}
                />
              </TitleRow> */}

              <Card
                // title="Chat Bots"
                subtitle={<>Select a chat bot or create a new one from your <strong>{config.googleBrand ? 'Google Drive' : 'Docs'}</strong></>}
                actions={[
                  <Button
                    key="add-docs"
                    action={onNewChatBot}
                    icon={config.googleBrand ? ['fab', 'google-drive'] : ['fas', 'file-import']}
                    iconPack="fontawesome"
                    // small
                    outline
                  > New Chat Bot</Button>,
                ]}
              >
                <Table
                  // selectable
                  search
                  data={bots.map(bot => ({
                    ...bot,
                    name: <Link url={`/bots/${bot.id}`} text={bot.name} bold />,
                    // link: <Link url={file.url} text={file.url} target="_blank"></Link>
                  }))}
                  loading={loading}
                  show={['name', 'created_at' /* , 'updated_at' */]}
                  header={[
                    { name: 'name', title: 'Chat Bot', sort: true, className: "" },
                    { name: 'created_at', title: 'Created', sort: true },
                    { name: 'updated_at', title: 'Updated', sort: true },
                    // { name: 'id', title: 'ID', sort: false },
                  ]}
                  formatters={{
                    created_at: utility.formatDate,
                    updated_at: utility.formatDate,
                  }}
                  // actions={{
                  //   // custom: [
                  //   //   { icon: 'eye', action: reveal },
                  //   //   { icon: 'rotate-ccw', action: revoke, condition: { col: 'active', value: true } }],
                  //   view: { url: '/bots', col: 'id' },
                  // }}
                  // bulkActions={{
                  //   delete: deleteKey,
                  // }}
                  href={bot => `/bots/${bot.id}`}
                  badge={{
                    col: 'active', color: 'green', condition: [

                      { value: true, color: 'green' },
                      { value: false, color: 'red' }

                    ]
                  }}
                />
              </Card>
            </Animate> :

            // <BlankSlateMessage
            //   title='Create a new Chat Bot'
            //   text={<>Select documents from your <strong>Google Drive</strong> to create your first chat bot</>}
            //   buttonText='New Chat Bot'
            //   buttonProps={{
            //     icon: ['fas', 'file-circle-plus'],
            //     iconPack: 'fontawesome',
            //     iconColor: 'white',
            //   }}
            //   // action={() => navigate('/account/bots/create')}
            //   loading={creating}
            //   disabled={creating || ready}
            //   action={createModal}
            //   marginTop='4em'
            // />

            <div className="mt-4">
              <CreateChatBot centered />
            </div>

          }
        </Fragment>
      }
    </Fragment>
  );
}
