import { useDeleteConfirm } from 'components/hooks/useDeleteConfirm';
import {
  Animate,
  BotsNav,
  Button,
  Card,
  Icon,
  Link,
  Loader,
  Table,
  ViewContext,
  config,
  useAPI,
  useGoogleDrivePicker
} from 'components/lib';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBotsTitle } from './hooks/useBotsTitle';

export function BotsDocs(props) {

  const { id: botId } = useParams();
  const [docs, setDocsState] = useState([]);
  const { data: docsData, loading } = useAPI(`/api/bot/${botId}/doc`);
  const { loading: addingDocs, fetch: addDocs } = useAPI(`/api/bot/${botId}/doc`, 'PUT');
  const { data: tokenData, loading: loadingToken } = useAPI('/api/user/token/google');
  const context = useContext(ViewContext);

  const { id: token_id, access: token } = tokenData || {};

  const setDocs = (docs) => {
    if (!docs) {
      setDocsState([]);
      return;
    }

    const state = docs.map(doc => {
      return {
        id: doc.id,
        key: doc.id,
        icon: <img src={doc.icon_url} alt={doc.name} className='w-4' />,
        name: doc.name,
        displayName: <Link href={doc.url} target="_blank" rel="noreferrer" bold>
          {doc.name}{" "}<Icon image="external-link" className="inline-block align-text-middle" size="14" /></Link>,
        type: doc.type,
        ...doc,
      }
    });
    setDocsState(state);
  };


  const deleteDoc = useDeleteConfirm(`/api/doc`, (form, data) => {
    // UI deletion is handled by the Table component
  }, { name: 'Doc', plural: 'Docs' });

  // Add docs and merge into docs array when docs are picked
  const onPicked = async (data) => {
    if (data.action === 'picked') {
      const addedDocs = await addDocs(data.docs.map(doc => ({
        ...doc,
        source_type: 'google_drive',
        token_id: token_id,
        source_id: doc.id,
      })));
      if (addDocs) {
        context.notification.show(`${addedDocs.length} docs added`, 'success', 2500);

        // Merge the docs
        const mergedMap = new Map();
        docs.forEach(doc => {
          mergedMap.set(`${doc.source_type}_${doc.source_id}`, doc);
        });
        addedDocs.forEach(addedDoc => {
          mergedMap.set(`${addedDoc.source_type}_${addedDoc.source_id}`, addedDoc);
        });
        const mergedDocs = Array.from(mergedMap.values());
        setDocs(mergedDocs);
      } else {
        context.handleError('Error adding docs');
      }
    };
  }
  const openPicker = useGoogleDrivePicker(token, onPicked);

  // set the page title
  useBotsTitle();

  // after fetching, set the docs for UI to render
  useEffect(() => {
    if (docsData?.length) {
      setDocs(docsData);
    }
  }, [docsData]);

  return (


    <Fragment>

      <BotsNav />

      {(loading && !docs?.length)
        ? <Loader />
        : <Animate>

          <Card
            title="Docs"
            subtitle="Add docs to your chat bot"
            actions={[
              <Button
                key="add-docs"
                action={openPicker}
                disabled={!token || addingDocs}
                loading={addingDocs}
                icon={config.googleBrand ? ['fab', 'google-drive'] : ['fas', 'file-import']}
                iconPack="fontawesome"
                // small
                outline
              > Add Docs</Button>,
            ]}
          >
            <Table
              search
              selectable
              sort
              data={docs}
              header={[
                { name: 'icon', title: '', sort: false, className: "w-14 max-w-14 !p-0" },
                { name: 'name', title: 'Name', sort: true },
                { name: 'type', title: 'Type', sort: true },
                // { name: 'id', title: 'ID', sort: false },
              ]}
              loading={loading || !docs}
              show={['icon', 'name', 'type']}
              actions={{
                delete: deleteDoc,
              }}
              bulkActions={{
                delete: deleteDoc,
              }}

            ></Table>
          </Card>

        </Animate >
      }
    </Fragment >

  );
}


/**
{files.map(file => (
  <Link href={file.url} target="_blank" rel="noreferrer">
    <Card>{file.name}</Card>
  </Link>
))}
 */