/***
*
*   ICON
*   Render an icon from feather icons
*
*   PROPS
*   color: dark/light/grey/green/orange/blue or hex code
*   pack: icon pack to use, default is feathericons
*   image: image to use (see: https://feathericons.com)
*   className: inject a parent class object
*
**********/

import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

export function Icon(props) {

  let color;

  const packs = {

    fontawesome: FontAwesomeIcon,
    feather: FeatherIcon,
    img: ({ icon, className, size }) => {
      return <span className={className}>
        <img src={icon} alt="icon" style={{
          height: size + 'px',
          width: size + 'px',
        }} />
      </span>
    }

  }

  switch (props.color) {

    case 'light':
      color = '#FFFFFF';
      break;

    case 'dark':
      color = '#758197';
      break;

    case 'grey':
      color = '#ccc';
      break;

    case 'green':
      color = '#8CC57D';
      break;

    case 'blue':
      color = '#73B0F4';
      break;

    case 'orange':
      color = '#F0AA61'
      break;

    case 'red':
      color = '#d95565';
      break;

    case 'purple':
      color = '#6363AC';
      break;

    default:
      color = props.color;
      break;

  }

  if (props.pack === 'fontawesome') {
    library.add(fab);
    library.add(fas)
    library.add(far)
  }

  const Icon = packs[props.pack || 'feather'];

  // size and style
  let size = props.size || 16;
  let style = {};

  /**
   * font awesome uses custom sizing 
   * https://fontawesome.com/docs/web/style/size
   * we override these in style to match
   * feather icon sizes
   * */
  if (props.pack === 'fontawesome') {
    size = undefined;
    style = { fontSize: size + 'px' }
  }

  return (
    <Icon
      color={color}
      icon={props.image}
      size={size}
      style={style}
      className={props.className}
    />
  )
}

