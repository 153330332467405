/***
*
*   PRICING
*   Modify the PricePlans component with your own features
*
**********/

import React from 'react';
import { Animate, Button, Row, PricePlans } from 'components/lib';

export function Pricing(props) {

  return (
    <Animate type='pop'>
      <Row color='tint' align='center' title='Simple, affordable pricing'>
        <PricePlans />
      </Row>
      <Row title='Enterprise Pricing' align='center' color="brand">
        <p className="mb-6">Got more documents? Contact us for custom integrations and help building integration into your apps.</p>
        <Button goto='/contact' text='Contact Us' className='inline-block' big />
      </Row>
    </Animate>
  )
}
