/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import { AuthContext, Button, CreateChatBot, Form, Message, Onboarding, useCreateBot, useNavigate } from 'components/lib';
import { useOnboarding } from 'components/onboarding/onboarding';
import React, { Fragment, useCallback, useContext } from 'react';

export function OnboardingView(props) {

  const context = useContext(AuthContext);
  const views = [{

    name: 'Welcome',
    description: `Hi ${context.user.name}! 👋`,
    component: <Welcome />

  }, {

    name: 'Add Docs',
    description: <>Select documents from your <strong>Google Drive</strong> to create your first chat bot</>,
    component: <AddDocs />

  }, {

    name: 'Chat Bot',
    description: ``,
    component: <ChatBot />

  }]

  // if (context.permission.admin) {
  //   views.push({

  //     name: 'Invite your team',
  //     description: 'Docsly is more fun when you invite your team.',
  //     component: <InviteUsers />,

  //   });
  // }

  if (context.user.duplicate_user) {
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser />

    })
  }

  return <Onboarding save onFinish='/bots' views={views} />

}

function DuplicateUser() {

  return (
    <Message
      type='warning'
      title={`You already have an account`}
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />
  )
}

function Welcome() {

  const { next } = useOnboarding();

  return (
    <Fragment>

      <p>Create custom chat bots like ChatGPT that can answer questions about your data by connecting your <strong>Google Drive <sup>&trade;</sup></strong></p>
      <p>Invite your team, embed it on your website, or keep them to yourself!</p>
      <p><strong>Thank you for using Docsly! 📝💬 </strong></p>
      <p><Button action={() => next()} text="Let's go" /></p>

    </Fragment>
  )
}

function AddDocs() {

  const { next, setBot } = useOnboarding();


  return (
    <Fragment>

      <CreateChatBot
        // @ts-ignore
        title={false}
        centered={false}
        onSuccess={useCallback((bot) => {
          setBot(bot);
          next();
        }, [next, setBot])}
        onSuccessNavigate={false}
      // onSuccessNotify={false}
      />

    </Fragment>
  )
}

function ChatBot(props) {

  const { bot, finish } = useOnboarding();
  const navigate = useNavigate();

  return (
    <>
      <Form
        disabled={!bot}
        inputs={{
          name: {
            label: 'Name',
            type: 'text',
            required: true,
            value: bot?.name,
            autofocus: true,
            hint: 'What should we call your bot?',
            errorMessage: 'Please name your bot'
          },
          description: {
            label: 'Description',
            type: 'textarea',
            required: true,
            value: bot?.description,
            hint: 'What does your bot answer questions about?',
            errorMessage: 'Please add a description for your bot'
          },
        }}
        url={`/api/bot/${bot?.id}`}
        method={'PATCH'}
        fullWidth={true}
        callback={res => {
          // const updatedBot = res?.data?.data;
          finish(false);
          navigate(`/bots/${bot.id}`);
        }}
        buttonText='Start Chatting'
      />
      {/* <div><pre>{JSON.stringify(bot, null, 2)}</pre></div> */}
    </>
  )
}

function InviteUsers(props) {

  return (
    <Form
      inputs={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}