const Style = {

  base: 'block outline-none',
  flex: 'flex justify-center items-stretch',
  homepage: 'block h-full w-32 max-w-full max-h-full',
  welcom: '',
  sidebar: 'h-8 mt-3 mb-0',
  mark: '',
  expanded: '',

  /** image tag */
  img: 'h-full max-h-full max-w-full',

}

export default Style;
