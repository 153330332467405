/***
*
*   usePlans hook
*   fetch, format and return the price plans
*
**********/

import { useAPI } from 'components/lib';
import { useEffect, useState } from 'react';

export function usePlans() {

  const [state, setState] = useState({ data: null, loading: false });
  const { data } = useAPI('/api/account/plans');


  useEffect(() => {

    setState({ loading: true });

    // format plans
    if (data?.plans) {

      let formatted = [];
      data.plans.map(plan => {

        let label = `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval})`
        return formatted.push({ value: plan.id, label: label, default: plan.default });

      });

      setState({ data: { list: formatted, active: data.active, raw: data }, loading: false });

    }
  }, [data]);

  return state;

}
