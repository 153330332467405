const Style = {
  base: '',
  container: 'flex flex-col flex-grow -m-8 -mt-[76px] md:-mt-[104px] pt-[45px] md:pt-[80px] h-screen max-h-screen overflow-hidden',
  messages: 'flex flex-col justify-start flex-grow bg-white overflow-y-scroll',
  form: 'grow-0 !flex-shrink p-20 w-full bg-white',
  settingsButton: `m-4 md:mt-4 md:mr-6
  flex flex-row items-center justify-center
  !bg-white
  !px-2 !py-4
  z-30
  opacity-75 hover:opacity-100
  `,
  buttonsWrapper: "flex flex-row justify-end h-0 overflow-visible"
}

export default Style;
