/***
*
*   SOCIAL SIGN IN BUTTONS
*   To sign up/in with Facebook, Google or Microsoft
*
**********/

import { Button, ClassHelper, Grid, config } from 'components/lib';
import { useState } from 'react';
import settings from 'settings';
import Style from './social.module.scss';
import React from 'react';

import googleIconSvg from './images/btn_google_light_normal_ios.svg';
import { arrayOf, bool, func, node, number, oneOfType, string } from 'prop-types';

export function SocialSignin(props) {

  const [loading, setLoading] = useState(props.network?.map(x => { return { [x]: false } }));
  const serverURL = settings[process.env.NODE_ENV].server_url;

  // construct query string
  const signup = props.signup ? true : false;
  const queryString = buildQueryString({
    invite: props.invite,
    signup: signup ? 1 : undefined,
    email: props.email,
  }, props.redirect);


  const text = n => {
    if (props.text) return props.text;
    return `${signup ? 'Sign up with' : 'Sign in with'} ${n.charAt(0).toUpperCase() + n.slice(1)}`
  }
  const networkIcon = n => {

    let icon = ['fab', n];
    let iconPack = 'fontawesome';
    let iconSize = '1x';
    let iconClassName = '';
    let buttonClassName = '';

    // Google Branding Guidelines
    if (n === 'google') {
      // @ts-ignore
      icon = googleIconSvg;
      iconPack = 'img'
      iconClassName = 'bg-white !left-[2px] rounded-l ml-0 my-0 h-[44px]';
    }

    // Custom Icons
    if (props.icon) {
      icon = props.icon;
      if (props.iconPack) {
        iconPack = props.iconPack;
      }
      if (props.iconSize) {
        iconSize = props.iconSize;
      }
      if (props.iconClassName) {
        iconClassName = `${iconClassName} ${props.iconClassName}`;
      }
    }

    return {
      icon, iconPack, iconSize, iconClassName,
      buttonClassName
    }
  }



  return (
    <div className={`${Style.signinButtons} ${props.className}`}>
      <Grid cols={1}>
        {props.network?.map(n => {

          const { icon, iconPack, iconSize, iconClassName,
            buttonClassName } = networkIcon(n);

          const className = ClassHelper(Style, {
            [n]: true,
            signinButton: true,
            className: buttonClassName
          });

          const url = `${serverURL}/auth/${n}?${queryString}`;
          return (
            <><Button
              key={n}
              loading={loading[n]}
              icon={icon}
              iconPack={iconPack}
              iconSize={iconSize}
              iconClassName={iconClassName}
              iconButton
              className={className}
              action={() => {
                if (props.onClick) {
                  const result = props.onClick(n);
                  if (result === false) return result;
                }

                setLoading({ [n]: true })
              }}
              url={url}
              text={text(n)}
              disabled={props.disabled}
            />{false && url}</>
          )
        })}
      </Grid>

      {props.showOr &&
        <span className={Style.or}>or</span>}

    </div>
  );
}

SocialSignin.propTypes = {
  className: string,
  disabled: bool,
  email: string,
  icon: oneOfType([node]),
  iconClassName: string,
  iconPack: string,
  iconSize: oneOfType([string, number]),
  invite: string,
  network: arrayOf(string),
  onClick: func,
  redirect: string,
  showOr: bool,
  signup: bool,
  text: string,
}

function buildQueryString(urlParts, redirect) {
  const queryParams = new URLSearchParams();

  // Add custom URL parts to the query parameters
  for (const [key, value] of Object.entries(urlParts)) {
    if (value !== undefined)
      queryParams.append(key, value);
  }

  // Add redirect_uri if provided, or use the default value
  if (redirect) {
    queryParams.append('redirect', redirect);
  }

  return queryParams.toString();
}