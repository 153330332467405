/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import { AppNav, AuthContext, Header, User } from 'components/lib';
import { Fragment, useContext } from 'react';
import Style from './app.module.scss';

export function AppLayout(props) {

  // context & style
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <AppNav
        items={[
          { label: 'Chat Bots', icon: 'comments', iconPack: 'fontawesome', link: '/bots' },
          { label: 'Dashboard', icon: 'activity', link: '/dashboard', permission: 'master' },
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={Style.app}>

        <Header title={props.title}>
          <User />
        </Header>

        {<props.children {...props.data} />}

      </main>
    </Fragment>
  );
}