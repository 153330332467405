import { Fragment, useState, useEffect } from 'react';
import { Loader } from 'components/lib';
// import Style from './heading.module.scss';
import Style from './heading.tailwind.js';

export function Heading(props) {


  const [state, setState] = useState();

  useEffect(() => {


  }, []);

  return (

    <header className={Style.heading}>
      <h1 className={Style.title}>{props.children}</h1>
    </header>
  );
}
