/***
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import { Animate, Card, Form, Row } from 'components/lib';
import React from 'react';

export function ForgotPassword(props) {

  return (
    <Animate type='pop'>
      <Row title='Reset Your Password'>
        <Card restrictWidth center>

          <p className='mb-5'>Enter your email address and we'll send you
            instructions to reset your password.</p>

          <Form
            inputs={{
              email: {
                label: 'Email',
                type: 'email',
                required: true
              }
            }}
            url='/api/auth/password/reset/request'
            method='POST'
            buttonText='Reset Password'
          />

        </Card>
      </Row>
    </Animate>
  );
}
