/***
*
*   LINK
*   Routes a new view within the application router
*   Use this instead of <a> to avoid reloading the page
*
*   PROPS
*   url: the destination as defined in /app/app.js
*   title: link title
*   text: link text
*   btn: display a button
*   small: display a small button
*   className: apply a custom css class
*
**********/

import { NavLink } from 'react-router-dom';
import { ClassHelper } from 'components/lib';
import Style from './link.tailwind.js';

export function Link(props) {

  const linkStyle = ClassHelper(Style, {

    white: props.color === 'white',
    dark: props.color === 'dark',
    defaultColor: !props.color,
    bold: props.bold || props.action,
    underline: props.underline,
    hoverUnderline: !props.underline,
    className: props.className,

  });

  // fallback for href
  const children = props.children || props.text;
  const href = props.url || props.href;


  if (href?.includes('http')) {
    return (
      <a href={href} title={props.title} target={props.target || "_blank"} className={linkStyle}>
        {children}
        {props.action && <>{" "}<span aria-hidden="true">&rarr;</span></>}
      </a>
    )
  }

  return (
    <NavLink
      to={href}
      target={props.target}
      className={linkStyle}
      title={props.title}
      activeclassname='active'>

      {children}
      {props.action && <>{" "}<span aria-hidden="true">&rarr;</span></>}

    </NavLink>

  );
}