/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import { ClassHelper, Link } from 'components/lib';
import { useEffect, useState } from 'react';
import LogoColor from './images/color.svg';
import LogoMarkColor from './images/mark/color.svg';
import LogoMarkWhite from './images/mark/white.svg';
import LogoWhite from './images/white.svg';
import Style from './logo.tailwind.js';

export function Logo(props) {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logoSources = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite
    }
  }

  const [src, setSource] = useState(logoSources[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']);


  const logoStyle = ClassHelper(Style, {
    flex: true,
    homepage: !props.sidebar && props.homepage !== false,
    sidebar: props.sidebar,
    mark: props.mark,
  });

  useEffect(() => {

    const nextSrc = logoSources[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']
    setSource(nextSrc);
  }, [logoSources, props.color, props.mark]);

  const url = props?.href || '/'

  return (
    <Link url={url} className={logoStyle}>
      <img className={Style.img} src={src} alt='Logo' />
      {/* mark={props.mark.toString()} */}
    </Link>
  )
}
