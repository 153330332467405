/***
*
*   CreateChatBot Component
*   Multipart form to
*     1. Select a document source
*     2. Authorize access to the source
*     3. Select documents from the source
*     4. Create a new chat bot
*
*   PROPS
*   props: description
*
**********/

import { Animate, Button, ClassHelper, Loader, useCreateBot, useGoogleDrivePicker } from 'components/lib';
import React from 'react';
import Style from './createchatbot.tailwind.js';
import { bool, func, node, oneOfType, string } from 'prop-types';

import googleDriveIcon from '../social/images/logo_drive_2020q4_color_2x_web_64dp.png';

export function CreateChatBot({
  title = 'Create a new Chat Bot',
  text = <>Select documents or folders from your <strong>Google Drive <sup>&trade;</sup></strong> to create your first chat bot</>,
  centered = false,
  onSuccess = (_) => { },
  onSuccessNavigate = true,
  onSuccessNotify = 'Bot created succesfully',
  ...props
}) {
  const { creating, createFromDocs } = useCreateBot({ onSuccess, onSuccessNavigate, onSuccessNotify })

  const onPicked = async (data) => {
    console.log('CreateChatBot.onPicked', { data })
    if (data.action === 'picked') {
      createFromDocs(data.docs);
    };
  }

  const { openPicker: openGoogleDrivePicker, enabled } = useGoogleDrivePicker(onPicked);


  return (
    <div className={ClassHelper(Style, {
      centered: centered,
    })}>

      {title &&
        <h2 className={Style.title}>
          {title}
        </h2>
      }

      {text &&
        <p>{text}</p>
      }

      <Button
        text={"Import from Google Drive"}
        action={openGoogleDrivePicker}
        icon={googleDriveIcon}
        iconPack='img'
        outline
        color="white"
        className='inline-block mt-4 !mb-0 !float-none'
        disabled={enabled === false || creating}
        loading={enabled === false || creating}
      />

      {creating && <Animate type="slidedown"><div className="mt-4 text-neutral-400 italic">Creating bot...
        {/* <span className="relative ml-8"><Loader /></span> */}
      </div></Animate>}
    </div>
  );
}

CreateChatBot.propTypes = {
  centered: bool,
  title: oneOfType([string, bool]),
  text: node,
  onSuccess: func,
  onSuccessNavigate: oneOfType([bool, string]),
  onSuccessNotify: oneOfType([bool, string]),
}