
export const formatDate = (date, options = {}) => {

  const dateObject = (typeof date === 'string') ? new Date(date) : date;

  // return date;
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    ...options,
  }).format(dateObject);
  return formattedDate.replace(',', ' at');
};

const formatElapsedTime = (elapsedMilliseconds) => {
  if (elapsedMilliseconds === 0)
    return '-';

  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);

  const hours = elapsedHours;
  const minutes = elapsedMinutes % 60;
  const seconds = elapsedSeconds % 60;

  // if (hours === 0 && minutes === 0 && seconds === 0) {
  //     return '-';
  // }

  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = (hours > 0 || minutes > 0) ? `${minutes}m ` : '';
  const secondsString = `${seconds}s`;

  return `${hoursString}${minutesString}${secondsString}`;
};

export const timeElapsedString = (created_at, completed_at) => {
  const startTime = new Date(created_at);
  const endTime = completed_at ? new Date(completed_at) : new Date();

  const elapsedMilliseconds = endTime - startTime;
  return formatElapsedTime(elapsedMilliseconds);
};

export function getPath(url) {
  let parsedUrl;

  if (!url) {
    parsedUrl = new URL(window.location.href);
  } else {
    parsedUrl = new URL(url);
  }

  const path = parsedUrl.pathname;
  const query = parsedUrl.search;  // search property returns the query string
  const hash = parsedUrl.hash;

  return path + query + hash;
}

export function getQueryParam(name, url) {
  let parsedUrl;

  if (!url) {
    parsedUrl = new URL(window.location.href);
  } else {
    parsedUrl = new URL(url);
  }

  const queryParams = parsedUrl.searchParams;
  return queryParams.get(name);
}
