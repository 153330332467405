import { arrayOf, node, string } from 'prop-types';
import React from 'react';
import { ChatContainer } from './container/container';
import { ChatContext, ChatProvider } from './context';
import { messagePropType } from './propTypes';

function ChatWithProvider({ botId, messages, children }) {
  return (
    <ChatProvider botId={botId} defaultMessages={messages}>
      <ChatContainer >
        {children}
      </ChatContainer>
    </ChatProvider>
  );
}

ChatWithProvider.propTypes = {
  botId: string.isRequired,
  messages: arrayOf(messagePropType),
  children: node
};

export const Chat = Object.assign(ChatWithProvider, {
  Provider: ChatProvider,
  Context: ChatContext,
});
