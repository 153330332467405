import { useContext } from "react";
import { ViewContext } from "components/lib";

/**
 * useDeleteConfirm hook
 * 
 * @param {string} url - the DELETE api endpoint
 * @param {object} options - the options object
 * @param {function} onSuccess - called with response data after successful completion
 * @param {string} options.name - the name of the item to be deleted
 * @param {string} options.plural - the plural name of the item to be deleted 
 * @returns {function} - the deleteConfirm function
 */
export function useDeleteConfirm(
  url = '/api/UNDEFINED',
  onSuccess = () => { },
  options = {}
) {
  const {
    name = 'Item',
    plural = 'Items',
    buttonText = 'Delete',
  } = options;

  const context = useContext(ViewContext);

  /**
     * Asks for confirmation and deletes the specified data if confirmed.
     *
     * @param {object} data - The data to be deleted. Can be an object containing an id property or an array of objects.
     * @param {function} callback - The callback function to be called after the confirmation and deletion.
     * @returns {void}
     */
  function deleteConfirm(data, callback) {

    const bulk = Array.isArray(data);
    const id = bulk ? data.map(x => x.id) : data.id;

    context.modal.show({
      title: `Delete ${bulk ? plural : name}`,
      text: `Are you sure you want to delete 
                ${bulk ? `these ${plural}?` : `this ${name}?`}
                This action cannot be undone.`,
      buttonText,
      form: {
        id: {
          type: 'hidden',
          value: id,
        }
      },
      url,
      destructive: true,
      method: 'DELETE',

    }, (form, data) => {

      // works with table.bulkActions to remove rows
      if (callback)
        callback();

      if (onSuccess)
        onSuccess(form, data)

    });
  }

  return deleteConfirm;
}