const Style = {

  base: '',
  defaultColor: 'text-blue-500',
  white: 'text-white',
  dark: 'text-slate-500',
  bold: 'font-semibold',
  underline: 'underline',
  hoverUnderline: 'no-underline hover:underline',
}

export default Style;