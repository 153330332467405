import Axios from 'axios';

// components
// @ts-ignore
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { View } from 'components/lib';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider, PrivateRoute } from './auth';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

// 404
import { NotFound } from 'views/error/404';

// tailwind css
import '../css/output.css';

// settings
const Settings = require('settings.json');
const publishableAPIKey = Settings[process.env.NODE_ENV].stripe.publishableAPIKey;

const StripePromise = loadStripe(publishableAPIKey);

const routes = [
  // ...require('routes/setup').default,
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/bots').default,
  ...require('routes/website').default,
];

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  // Rollbar error reporting
  const rollbarConfig = {
    accessToken: '0b7cc0ed1dc74de1b19c35cef59f13fa',
    environment: process.env.NODE_ENV || 'unknown',
  };
  function TestError() {
    const a = null;
    // if querystring has error=true, throw an error
    if (window.location.search.includes('error=true')) {
      throw new Error('Test error');
    }
  }

  const ErrorDisplay = ({ error, resetError }) => {
    switch (process.env.NODE_ENV) {
      case 'development':
        return (<div>
          <h1> A following error has occured:</h1>
          <p>{error.toString()}</p>
          <button onClick={resetError}>Try again</button>
        </div >)
      case 'production':
      default:
        return (<div>
          <p>An unknown error occured.</p>
        </div >)
    }
  };

  // render the routes
  return (

    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary
        fallbackUI={ErrorDisplay}
      >
        <TestError />

        <Elements stripe={StripePromise}>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                {routes.map((route) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        // user PrivateRoute.public to allow unauthenticated access without re-render
                        <PrivateRoute
                          permission={route.permission}
                          public={!route.permission || route.permission === 'unauthenticated'}
                        >
                          <View display={route.view} layout={route.layout} title={route.title} />
                        </PrivateRoute>
                        // : <View display={route.view} layout={route.layout} title={route.title} />
                      }
                    />
                  );
                })}

                {/* 404 */}
                <Route path="*" element={<View display={NotFound} layout="home" title="404 Not Found" />} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </Elements>
      </ErrorBoundary>
    </RollbarProvider>
  );
}
