import { Navigate } from 'react-router-dom';
import { BotsChat } from 'views/bots/chat';
import { BotsDocs } from 'views/bots/docs';
import { BotsEdit } from 'views/bots/edit';
import { BotsList } from 'views/bots/list';
import { BotsQuestions } from 'views/bots/questions';
import { BotsTraining } from 'views/bots/training';

const Routes = [
  {
    path: '/bots',
    view: BotsList,
    layout: 'app',
    permission: 'user',
    title: 'Chat Bots'
  },
  {
    path: '/bots/:id',
    view: () => (<Navigate to="./chat" replace />),
    layout: 'app',
    title: 'Bot'
  },
  {
    path: '/bots/:id/chat',
    view: BotsChat,
    layout: 'app',
    title: 'Bot'
  },
  {
    path: '/bots/:id/docs',
    view: BotsDocs,
    layout: 'app',
    title: 'Bot'
  },
  {
    path: '/bots/:id/questions',
    view: BotsQuestions,
    layout: 'app',
    title: 'Bot'
  },
  {
    path: '/bots/:id/training',
    view: BotsTraining,
    layout: 'app',
    title: 'Bot'
  },
  {
    path: '/bots/:id/edit',
    view: BotsEdit,
    layout: 'app',
    title: 'Bot'
  },
]

export default Routes;
