/***
*
*   BOTS NAV
*   BOTS DETAIL sub nav that renders different options based
*   on user permissions.
*
**********/

import { SubNav } from 'components/lib';
import { useParams } from 'react-router-dom';

export function BotsNav(props) {

  const { id } = useParams();

  return (
    <SubNav items={[

      { link: `/bots/${id}/chat`, label: 'Chat' },
      { link: `/bots/${id}/docs`, label: 'Docs' },
      // { link: `/bots/${id}/questions`, label: 'Questions' },
      { link: `/bots/${id}/training`, label: 'Train' },
      { link: `/bots/${id}/edit`, label: 'Edit' },
      // { link: '/account/billing', label: 'Billing', permission: 'owner' },
      // { link: '/account/users', label: 'Users', permission: 'admin' }

    ]} />
  );
}