import { ClassHelper, Error, Icon, Label } from 'components/lib';
import { number } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Style from './input.tailwind.js';



export function TextInput(props) {

  const error = props.errorMessage || 'Please enter a value';

  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props?.autoFocus]);

  function validate(e) {

    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '')
      valid = true;

    if (props.required && value !== '')
      valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);

  }

  // style
  const textStyle = ClassHelper(Style, {

    textbox: true,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
    fullWidth: props.fullWidth !== false,
    className: props.className,

  });

  // $("textarea").each(function () {
  //   this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
  // }).on("input", function () {
  //   this.style.height = 0;
  //   this.style.height = (this.scrollHeight) + "px";
  // });

  return (
    <div className={`${Style.input} ${props.wrapperClassName}`} >

      {
        props.label &&
        <Label
          text={props.label}
          required={props.required}
          for={props.name}
        />
      }


      {props.type === 'textarea' ?

        <textarea
          id={props.id}
          name={props.name}
          value={props.value}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={e => {
            return props.onChange?.(props.name, e.target.value, undefined)
          }}
          onBlur={e => validate(e)}
          autoFocus={props.autoFocus}
          rows={props.rows || 3}
          onInput={(props.maxRows || props.maxRows) ? fitToRows(props.minRows, props.maxRows) : undefined}
          ref={inputRef}
          onKeyDown={async function (e) {
            // TODO: wrap in submitOnEnter boolean flag and pass up to the form builder in /components/chat/form
            // TODO: allow users to submit on enter, reserve shift+enter for new line
            if (e.keyCode == 13 && !e.shiftKey /* && (e.metaKey || e.ctrlKey)*/) {
              e.preventDefault(); // Prevents the default action to be triggered. 
              if (props.submitForm) {
                await props.submitForm();
                // TODO: clear the input at the Form control level

                if (props.maxRows || props.maxRows || true) {
                  // delay 100ms to allow the form to submit before resizing the textarea
                  setTimeout(() => {
                    fitToRows(props.minRows, 2)(e);
                  }, 1);
                }
              }
              // $(this).closest('form').submit(); // Submits the closest form.
            }
          }}
        >
        </textarea>

        :

        <input
          type='text'
          id={props.id}
          name={props.name}
          value={props.value || ''}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={e => props.onChange?.(props.name, e.target.value, undefined)}
          onBlur={e => validate(e)}
          autoFocus={props.autoFocus}
          ref={inputRef}
        />
      }

      {props.hint &&
        <Label
          text={props.hint}
          for={props.name}
          className={"mt-2 text-sm text-gray-500 normal-case"}
        />}

      {props.valid === false &&
        <Error message={error} />}

      {props.valid === true && !props.hideSuccessIcon &&
        <Icon
          image='check'
          color='#8CC57D'
          className={Style.successIcon}
          size={16}
        />}

    </ div>
  );
}

TextInput.propTypes = {
  minRows: number,
  maxRows: number,
}

function fitToRows(minRows = 2, maxRows = 8) {
  return (e) => {
    // @ts-ignore
    const { value } = e.target;
    var rowCount = value.split('\n').length;
    // @ts-ignore
    const lineHeight = parseInt(window.getComputedStyle(e.target).lineHeight);
    const rowsHeight = rowCount * lineHeight;
    const minHeight = minRows * lineHeight;
    const maxHeight = maxRows * lineHeight;
    const padding = 20;
    const height = Math.max(minHeight, Math.min(maxHeight, rowsHeight));
    // @ts-ignore
    e.target.style.height = 'auto';
    // @ts-ignore
    e.target.style.height = (rowCount > 1 ? height + padding : height) + "px";
  }
}