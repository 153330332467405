import { Animate, Card, ClassHelper, Icon } from 'components/lib';
import { bool, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Style from './message.tailwind';

import logo from 'components/logo/images/mark/color.svg';

export function ChatMessage({ sender = null, text, className, bottom }) {

  const [state, setState] = useState();

  useEffect(() => {


  }, []);

  const showAvatars = ['system', 'user'];
  const showSender = ['sources'];


  return (
    <Animate type="slideup" className={`${bottom && 'mt-auto'}`}>
      <Card
        className={ClassHelper(Style, {
          system: sender === 'system',
          sources: sender === 'sources',
          className: className
        })}
      >
        <div className={Style.content}>
          {sender === 'system' &&
            <img className={Style.avatar} src={logo} alt={sender} />}
          {sender === 'user' &&
            <Icon className={Style.avatar} image="user" alt={sender} />}

          {sender && showSender.includes(sender) && <small>{sender}</small>}
          {typeof text === 'string'
            ? text.split('\n').map((item, i, arr) =>
              <span key={i}>
                {item}
                {i < arr.length - 1 && <br />}
              </span>
            )
            : text}
        </div>
      </Card>
    </Animate>
  );
}

ChatMessage.propTypes = {
  sender: string,
  text: oneOfType([string, object]),
  bottom: bool,
  className: string,
}
