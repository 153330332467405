/***
*
*   ONBOARDING
*   Flow to help users set up the app, accepts multiple views/steps 
*   On finish/cancel, the user will be marked as onboarded
*
*   PROPS
*   views: array of views to render containing keys: 
*     name, description and component to render
*
**********/

import Axios from 'axios';
import { Animate, Button, CheckList, Event, Logo, useNavigate } from 'components/lib';
import React, { useState } from 'react';
import Style from './onboarding.tailwind.js';

const OnboardingContext = React.createContext({
  bot: null,
  setBot: (_) => { },
  prev: () => { },
  next: () => { },
  finish: (_) => { },
  hash: '',
  activeView: 0
});
export const useOnboarding = () => React.useContext(OnboardingContext);

export function Onboarding(props) {

  const navigate = useNavigate();
  const hash = window.location.hash;
  const [activeView, setActiveView] = useState(hash ? hash.substring(1) - 1 : 0);
  const [bot, setBot] = useState();

  if (!props?.views?.length)
    return false;

  const view = props.views[activeView];

  async function goTo(view) {

    window.location.hash = view + 1;
    setActiveView(view);

  }

  async function finish(redirect = true) {

    if (props.save)
      await Axios.patch('/api/user', { onboarded: true });

    if (!redirect) return;

    navigate(props.onFinish || '/bots');

  }

  function prev() {
    goTo(activeView - 1)
  }

  function next() {
    goTo(activeView + 1)
  }

  return (
    <Animate type='pop-center'>
      <div className={Style.onboarding}>

        <Button
          icon='arrow-right-circle'
          text='Skip Intro'
          position='absolute'
          alignRight
          className={Style.skip}
          size={16}
          action={() => {

            Event('cancelled_onboarding');
            finish();

          }}
        />

        <section className={Style.sidebar}>

          <Logo className={Style.logo} homepage={false} />

          <CheckList color='white'
            className={Style.checklist}
            hideCross interactive
            items={props.views.map((view, i) => {

              return {
                name: view.name,
                checked: i <= activeView ? true : false, onClick: () => goTo(i),
                hideCross: true
              }

            })
            } />

        </section>

        <section className={Style.main}>

          <header className={Style.header}>
            <h2 className={Style.name}>
              {view.name}
            </h2>

            {view.description &&
              <span className={Style.description}>
                {view.description}
              </span>
            }
          </header>

          <article className={Style.article}>
            <OnboardingContext.Provider value={{ bot, setBot, prev, next, finish, hash, activeView }}>
              {view.component}
            </OnboardingContext.Provider>
          </article>

        </section>

        <nav className={Style.nav}>

          {activeView > 0 &&
            <Button
              icon='chevron-left'
              alignLeft
              size={16}
              text='Prev'
              className={Style.prev}
              action={() => prev()}
            />
          }

          {activeView < props.views.length - 1 &&
            <Button
              icon='chevron-right'
              alignRight
              size={16}
              text='Next'
              className={Style.next}
              action={() => next()}
            />
          }

          {activeView === props.views.length - 1 &&
            <Button
              icon='check-circle'
              alignRight
              size={16}
              text='Finish'
              className={Style.next}
              action={() => {

                Event('completed_onboarding');
                finish();

              }}
            />
          }

        </nav>

      </div>
    </Animate>
  );
}