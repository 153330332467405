import { ViewContext, useAPI, useNavigate } from "components/lib";
import { bool, oneOfType, string } from "prop-types";
import { useContext } from "react";
import { useGoogleDrivePicker } from "./useGoogleDrivePicker";

export function useCreateBot({
  onSuccessNotify = 'Bot created succesfully',
  onSuccessNavigate = true,
  onSuccess = (_) => { }
}) {
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const { data: tokenData, loading: loadingToken } = useAPI('/api/user/token/google');
  const { fetch: createBot, loading: creating } = useAPI('/api/bot', 'POST');
  const { id: token_id, access: token } = tokenData || {};

  // Create a bot and redirect when docs are picked
  const onPicked = async (data) => {
    if (data.action === 'picked') {
      createFromDocs(data.docs);
    };
  }

  const createFromDocs = async (docs) => {
    const botData = {
      // name: 'My New Bot',
      docs: docs.map(doc => ({
        ...doc,
        source_type: 'google_drive',
        source_id: doc.id,
        token_id: token_id
      })),
    };
    const bot = await createBot(botData);
    if (bot) {
      if (onSuccess)
        onSuccess(bot);
      if (onSuccessNotify)
        context.notification.show(onSuccessNotify, 'success', 2500);
      if (onSuccessNavigate)
        navigate(`/bots/${bot.id}`);
    } else {
      context.handleError('Error creating bot');
    }
  }

  // Google Drive File Picker
  const openPicker = useGoogleDrivePicker(token, onPicked);

  return {
    openPicker,
    initializing: loadingToken,
    ready: !loadingToken,
    creating: creating,
    createFromDocs,
  }
}

useCreateBot.propTypes = {
  onSuccessNotify: oneOfType([bool, string]),
}