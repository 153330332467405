const Style = {

  base: 'inline-block bg-gray-500 mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  gray: '!bg-gray-500',
  red: '!bg-red-500',
  blue: '!bg-blue-500',
  green: '!bg-emerald-500',
  orange: '!bg-orange-500',

}

export default Style;