import { Animate, Button, ClassHelper, Icon } from 'components/lib';
import { node } from 'prop-types';
import React, { useContext, useState } from 'react';
import { ChatContext } from '../context.js';
import { ChatForm } from '../form/form.js';
import { ChatMessages } from '../messages/messages.js';
import Style from './container.tailwind.js';

export function ChatContainer({ children }) {
  const [state, setState] = useState();
  const { messages, addMessage, botId } = useContext(ChatContext);


  return (
    <Animate type="slideup">
      <div
        className={ClassHelper(Style, {
          container: true
        })}
      >
        {children}
        <div className={Style.buttonsWrapper}>
          <Button
            outline
            goto={`/bots/${botId}/edit`}
            className={Style.settingsButton}
            text={<><Icon image="settings" outline></Icon></>}
          />
        </div>
        <ChatMessages
          messages={messages}
          className={Style.messages}
        />
        <ChatForm className={Style.form} />
      </div>
    </Animate>
  );
}

ChatContainer.propTypes = {
  children: node,
}

// const sampleMessage = [
//   {
//     sender: 'system',
//     text: `Hi! My name is Kim Blogger Bot. I'm an expert in documents about modern leadership, management skills, and getting the most out of your team.`,
//   },
//   {
//     sender: 'system',
//     message: `Ask me anything`,
//   },
//   {
//     sender: 'user',
//     message: 'Hello, how can I be a good leader?.',
//   },
//   {
//     sender: 'system',
//     message:
//       'A good leader serves others, takes ownership when mistakes happen, accepts failures, creates environments that foster great outcomes, inspires others to act, gets attention for their team, listens to their team, and embraces emotional intelligence, trust and empowerment, collaboration and inclusion, adaptability, and continuous learning. They also understand and grow their team, and celebrate their successes even if they leave. Being in charge, taking credit, big paychecks, or controlling and bossing people around do not make a good leader.',
//     sources: [
//       'https://docs.google.com/document/d/1_sym6Y8a8sPe-HE15_aqAb1iFcE-2vIRxFhg7f8j7hQ/edit?usp=drive_web',
//       'https://docs.google.com/document/d/1Md2kIAnK7mLZ_XyAh0MFq8IxE4JA6JouOTo3sONLUEU/edit?usp=drivesdk',
//       'https://docs.google.com/document/d/127dgKUz6PKpP4COR1663VVdWKfXu3GTPGpE2WJHW8Vo/edit?usp=drivesdk',
//       'https://docs.google.com/document/d/1Z1qnSn--KEnMiiyis0pAAQ4p3FU_wwbrV9J9yYq7s-w/edit?usp=drivesdk',
//     ],
//   },
//   {
//     sender: 'user',
//     message: 'Thank you!',
//   },
//   {
//     sender: 'system',
//     message: "No problem, I'm here to help. What else would you like to know?",
//   },
// ];
