/***
*
*   PRICE PLANS
*   Pricing table for your landing/pricing page
*   Update the features array in /config file with your own
*
**********/

import { Card, CheckList, Button, useNavigate, usePlans } from 'components/lib';
import Style from './pricing.tailwind.js';

export function PricePlans(props) {

  const navigate = useNavigate();
  const plans = usePlans();

  if (!plans.data)
    return false;

  return (
    <section className={Style.plans}>
      {plans?.data?.raw?.plans.map((plan, index) => {

        const limits = [...Object.keys(plan.limits || {}).map(key => {

          const entity = key;
          const limit = plan.limits[key] || 'Unlimited';

          let name = `${limit.toLocaleString('en-US')} ${toTitleCase(entity)}`
          if (key === 'bots') name = `${limit} Chat ${(limit > 1 || !plan.limits[key]) ? 'Bots' : 'Bot'}`;
          if (key === 'docs') name = `${limit.toLocaleString('en-US')} Docs`;
          if (key === 'doc_size') {
            const wordsPerKb = 167;
            const wordsPerPage = 250;
            const wordsPerDoc = limit * wordsPerKb;
            const pagesPerDoc = Math.ceil(wordsPerDoc / wordsPerPage);
            name = <>{limit} kb per Doc<br /><span className="italic text-xs text-slate-400">about {pagesPerDoc} pages of text</span></>
          };
          if (key === 'trainings') {
            const frequency = getFrequency(limit);
            name = `Train ${frequency}`;
          }

          return { key, name, checked: false, labelOnly: true };
        })]

        return (
          <Card shadow rounded key={plan.id} className={Style.plan}>

            <div className={Style.name}>
              {plan.name}
            </div>

            <div className={Style.price}>
              {plan?.currency?.symbol}{plan.price}
              <small className={Style.interval}>{plan.interval && '/ '}
                {plan.interval}
              </small>
            </div>
            <div className="hidden md:block mt-8 mb-4">
              <Button text='Sign Up' fullWidth className={Style.button} action={e => navigate('/signup')} />
            </div>
            <div className='flex-grow'>
              <CheckList items={plan.features} className={Style.features} />
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-sm text-gray-500 border-t border-gray-300"></span>
              </div>
              <CheckList items={limits} className={Style.features} />
            </div>
            <Button text='Sign Up' fullWidth className={Style.button} action={e => navigate('/signup')} />

          </Card>
        )
      })}
    </section>
  );
}

function getFrequency(occurrences) {
  if (occurrences <= 0) {
    return 'Invalid input';
  }

  const units = [
    { name: 'day', value: 30 },
    { name: 'week', value: 4 },
    { name: 'month', value: 1 },
  ];

  for (const unit of units) {
    const times = occurrences / unit.value;
    if (times > 1) {
      return `${Math.round(times)}x per ${unit.name}`;
    }
  }

  return 'Less than 1x per month';
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
