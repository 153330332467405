import { useParams } from 'react-router-dom';
import { Fragment } from 'react';
import {
  Animate, Loader, useAPI, Card, BotsNav
} from 'components/lib';
import { useBotsTitle } from './hooks/useBotsTitle';

export function BotsQuestions(props) {

  const { id: botId } = useParams();
  const { data: bot, loading } = useAPI(`/api/bot/${botId}`);

  useBotsTitle();

  return (

    <Fragment>

      <BotsNav />

      {loading
        ? <Loader />
        : <Animate>

          <Card
            title="Questions"
          >
            [...questions]<br />
            [...questions]<br />
            [...questions]<br />
            [...questions]<br />
            [...questions]<br />
          </Card>

        </Animate>
      }
    </Fragment>

  );
}