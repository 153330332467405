/***
 *
 *   CHAT CONTEXT
 *
 **********/

import { useAPI } from 'components/lib';
import { arrayOf, node, string } from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { ChatMessageSource } from './message/source';
import { messagePropType } from './propTypes';

const defaultChatContext = {
  messages: [],
};

// create chat context
export const ChatContext = createContext(defaultChatContext);

export function ChatProvider({ botId, children, defaultMessages, ...props }) {

  const debug = true;
  const [messages, setMessages] = useState([...defaultMessages]);
  const [loading, setLoading] = useState(false);
  const {
    // data: question,
    loading: creatingQuestion,
    fetch: createQuestion,
  } = useAPI(`/api/bot/${botId}/question`, 'POST');
  // const { fetch, loading } = useAPI('/api/chatbot', { method: 'POST' }')

  const addMessage = async (message) => {
    // const nextMessages = [...messages, message];
    const nextMessages =
      setMessages((prevMessages) => [...prevMessages, message]);
    // delay 500 ms
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    // setMessages([...nextMessages, { sender: 'system', text: 'response from server' }]);
    return nextMessages;
  };

  const addUserQuestion = async (question) => {
    if (!question) return;
    try {

      const userMessage = {
        sender: 'user',
        text: question.replace(/\sSOURCES:$/, ""),
      }
      addMessage(userMessage);
      setLoading(true);

      if (debug)
        console.log('🧘‍♀️ addUserQuestion', { question });

      const response = await createQuestion({
        query: question,
      });
      if (!response?.answer) {
        throw new Error('Error asking question');
      }
      if (debug)
        console.log('🧘‍♀️ addUserQuestion', { response });

      // add message 
      const systemMessage = {
        sender: 'system',
        text: response.answer,
      }
      addMessage(systemMessage)

      // add sources
      if (response.sources?.filter(source => source !== 'N/A')?.length > 0) {
        // sleep for 1 second
        await new Promise(r => setTimeout(r, 600));
        addMessage({
          sender: 'sources',
          text: <>{response?.sources.map((source, i) => (
            <ChatMessageSource key={`${source?.url}-i`} {...source} />
          ))}</>
        });
      }
      // resetForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (debug)
      console.log('🧘‍♀️ ChatContext.useEffect.messages', messages);
  }, [messages])

  return (
    <ChatContext.Provider
      value={{
        messages,
        addMessage,
        addUserQuestion,
        botId,
        loading,
      }}
      {...props}
    >
      {children}
    </ChatContext.Provider>
  );
}

ChatProvider.propTypes = {
  botId: string.isRequired,
  children: node,
  defaultMessages: arrayOf(messagePropType),
};
