/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/

import { AuthContext, Button, Icon, Logo } from 'components/lib';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './app.scss';

export function AppNav(props) {

  const context = useContext(AuthContext);

  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded
  // const expanded = true;
  // const setExpanded = () => { };

  const homepageUrl = context.user?.onboarded ? '/bots' : '/welcome';

  return (
    <nav className={`sidebar popup ${open && 'open'} ${expanded && 'expanded'}`}
      onMouseEnter={e => setExpanded(true)}
      onMouseLeave={e => setExpanded(false)}
    // onClick={e => setExpanded(!expanded)}
    >

      <Button
        icon={open ? 'x' : 'menu'}
        color={'dark'}
        size={12}
        className='btn-togglenav'
        action={e => setOpen(!open)}
      />

      <Logo
        mark={!expanded}
        sidebar
        href={homepageUrl}
      />
      <section className='nav-links'>
        {props.items?.map(item => {


          if (item.permission && !context.permission[item.permission])
            return false

          if (item.link) {
            return (
              <NavLink
                key={item.label}
                to={item.link}
                onClick={() => setOpen(false)}
                style={{ width: (100 / props.items.length) + '%' }}>

                {item.icon &&
                  <Icon
                    className='icon'
                    image={item.icon}
                    pack={item.iconPack}
                    size={open ? 15 : 18}
                    color={open ? 'dark' : 'light'}
                  />
                }
                {item.label &&
                  <span className='label'>
                    {item.label}
                  </span>
                }

              </NavLink>
            );
          }

          return (
            <div key={item.label} onClick={item.action}>

              {item.icon &&
                <Icon
                  className='icon'
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? 'dark' : 'light'}
                />
              }
              {item.label &&
                <span className='label'>
                  {item.label}
                </span>
              }
            </div>
          )
        })}
      </section>
    </nav>
  );
}
