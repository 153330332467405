/***
*
*   FORM
*   Self-validating form that accepts an object for construction
*   Read the full documentation on object formatting
*   https://docs.usegravity.app/gravity-web/components/form
*
*   PROPS
*   inputs: the object containing your form inputs
*   onSubmit: function to be executed on submit attempt
*   callback: function to be executed on successful submit
*   url: url to send the form to (optional)
*   method: HTTP request type
*   redirect: url to redirect to after a successful submit (optional)
*   buttonText: submit button text
*   cancel: true/false to toggle a cancel button (optional)
*   resetOnSubmit: true/false to reset the form after submit (optional)
*
**********/

import Axios from 'axios';
import { useContext, useEffect, useState } from 'react';

import {
  Button,
  CardInput,
  ClassHelper,
  DateInput,
  EmailInput,
  Fieldset,
  FileInput,
  FormHeader,
  FormLink,
  HiddenInput,
  NumberInput,
  PasswordInput,
  PhoneInput,
  Select,
  Switch,
  TextInput,
  URLInput,
  ViewContext, useNavigate
} from 'components/lib';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { bool, func, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import Style from './form.tailwind.js';


function Form(props) {

  // context & state
  const context = useContext(ViewContext);
  const [form, setForm] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileStore, setFileStore] = useState([]);
  const [processCreditCard, setProcessCreditCard] = useState(false);
  const navigate = useNavigate();
  let valid = true;
  const { fullWidth = true } = props;

  // store default inputs
  useEffect(() => {
    if (props.inputs && !defaultValues) {
      setDefaultValues(Object.keys(props.inputs).map(key => ([key, props.inputs[key].value])));
    }
  }, [props.inputs]);

  useEffect(() => {
  }, [defaultValues]);

  // inputs map
  const Inputs = {

    text: TextInput,
    textarea: TextInput,
    email: EmailInput,
    number: NumberInput,
    url: URLInput,
    date: DateInput,
    hidden: HiddenInput,
    phone: PhoneInput,
    password: PasswordInput,
    creditcard: CardInput,
    radio: Fieldset,
    select: Select,
    checkbox: Fieldset,
    selector: Fieldset,
    switch: Switch,
    header: FormHeader,
    link: FormLink,
    file: FileInput,

  }

  useEffect(() => {

    // if the form is valid and using
    // live updates, refresh the form
    if (valid && props.updateOnChange) {

      setForm(props.inputs);

    }

    // otherwise, only init if no form set
    else if (!form) {

      let data = { ...props.inputs };

      // init credit card
      if (data?.token) {

        data?.plan?.default === 'free' ?
          setProcessCreditCard(false) :
          setProcessCreditCard(true);

      }

      setForm(data);
    }
  }, [props, form, valid]);

  const resetForm = (data) => {
    const { inputs } = props;
    setForm(data || inputs)
  }

  if (!form)
    return;

  function update(input, value, valid) {

    let data = { ...form }

    // is it a file?
    if (value.length && value[0].name && value[0].type && value[0].size) {

      if (!fileStore[input]?.length)
        fileStore[input] = [];

      const newFiles = { ...fileStore }
      value.forEach(file => {

        // add or delete the file
        if (file.data && !fileStore[input].find(x => x.name === file.name)) {

          newFiles[input].push(file);


        }
        else if (!file.data) {

          newFiles[input].splice(newFiles[input].findIndex(x => x.name === file.name), 1);

        }
      })

      data[input].value = newFiles[input];
      data[input].valid = valid;
      setFileStore(newFiles);

    }
    else {

      // update input value & valid state
      data[input].value = value;
      data[input].valid = valid;

      // hide credit card input when selecting free plan
      if (props.inputs.token) {
        if (input === 'plan' && value === 'free') {

          setProcessCreditCard(false)

        }
        else if (input === 'plan' && value !== 'free') {

          setProcessCreditCard(true)

        }
      }
    }

    setForm(data);

    props.updateOnChange &&
      props.onChange({ input: input, value: value, valid: valid });

    props.submitOnChange && submit();

  }

  function validate() {

    // loop over each input and check it's valid
    // show error if input is required and value is
    // blank, input validation will be executed on blur

    let errors = [];
    let data = { ...form };

    // loop the inputs
    for (let input in data) {

      // validate credit card
      if (input === 'token') {
        if (processCreditCard && data.token.value.error) {

          data.token.valid = false
          errors.push(false);

        }
        else {

          data.token.valid = true;

        }
      }
      else {

        // standard input
        let inp = data[input];
        if (inp.value === undefined && inp.default) {

          data[input].value = inp.default;

        }

        if (inp.required) {
          if (!inp.value || inp.value === 'unselected') {

            inp.valid = false;
            errors.push(false);

          }
        }

        if (inp.valid === false) {

          errors.push(false);

        }
      }
    }

    if (errors.length) {

      // form isn't valid
      valid = false;
      setForm(data);
      return false;

    }
    else {

      // form is valid
      return true;

    }
  }

  async function submit() {

    // submit the form
    setLoading(true);
    let data = { ...form };

    // create the credit card token
    if (processCreditCard) {

      const cardElement = await props.elements.getElement(CardElement);
      const token = await props.stripe.createToken(cardElement);
      data.token.value = token.token;

    }

    // is the form valid?
    if (!validate()) {

      setLoading(false);
      return false;

    }

    // optimise data for server
    for (let input in form) {
      if (processCreditCard && input === 'token') {

        // procress credit card
        data[input] = form[input].value;

      }
      else if (input !== 'header') {

        // process single input & ignore headers
        data[input] = form[input].value;

      }
    }

    delete data.header;


    // submit the form or execute callback
    if (!props.url) {

      // onSubmit
      const response = await (
        props.onSubmit ? await props.onSubmit(data, resetForm) : undefined);

      // callback?
      if (props.callback)
        await props.callback(response);


      // reset
      if (props.resetOnSubmit)
        resetForm();

      setLoading(false);

      return false;

    }

    try {

      let formData = new FormData(), headers = {};
      if (Object.keys(fileStore).length) {

        headers['Content-Type'] = 'multipart/form-data';
        headers['Accept'] = 'application/json';

        for (let key in data) {

          // append files
          if (Array.isArray(data[key]) && Object.hasOwnProperty.call(data[key]?.[0], 'data')) {
            for (let i = 0; i < data[key].length; i++) {

              formData.append(key, data[key][i].data);

            }
          }
          else {

            // append text values
            formData.append(key, data[key]);

          }
        }

        data = formData;

      }

      let res = await Axios({

        method: props.method,
        url: props.url,
        data: data

      });

      // check for 2-factor payment requirement
      if (res.data.requires_payment_action) {

        const stripeRes =
          await props.stripe.handleCardPayment(res.data.client_secret);

        if (stripeRes.error) {

          setLoading(false);
          context.handleError(stripeRes.error.message);
          return false;

        }
        else {

          // re-send the form
          data.stripe = res.data;
          res = await Axios({

            method: props.method,
            url: props.url,
            data: data

          });
        }
      }

      // finish loading
      setLoading(false);

      // close the modal
      context.modal.hide(false, res.data.data);

      // callback?
      if (props.callback)
        await props.callback(res);

      // redirect?
      if (props.redirect)
        navigate(props.redirect);

      // reset
      if (props.resetOnSubmit)
        setForm(props.inputs);

      // success notification
      if (res.data.message)
        context.notification.show(res.data.message, 'success', true);

    }
    catch (err) {

      // handle error
      setLoading(false);
      context.modal.hide(true);

      // show error on input
      if (err.response?.data?.inputError) {

        let data = { ...form }
        const input = err.response.data.inputError;
        data[input].valid = false;
        data[input].errorMessage = err.response.data.message;
        valid = false;
        setForm(data);
        return false;

      }
      else {

        // general errors handled by view
        context.handleError(err);

      }
    }
  }

  let inputsToRender = [];
  const formStyle = ClassHelper(Style, {
    ...props, ...{

      loading: props.loading || loading

    }
  });

  // map the inputs
  Object.keys(form).map(name => {

    // get the values for this input
    const data = form[name];
    data.name = name;
    inputsToRender.push(data);
    return inputsToRender;

  });

  // render the form
  return (

    <form
      action={props.action}
      method={props.method}
      onSubmit={submit}
      className={formStyle}
      encType={fileStore.length && 'multipart/form-data'}
      noValidate>

      {inputsToRender.map(input => {

        if (input.type === null)
          return false;

        if (!input.type)
          input.type = 'text';

        if (input.type === 'creditcard' && !processCreditCard)
          return false;

        const Input = Inputs[input.type];

        return (
          <Input
            accept={input.accept}
            autoFocus={input.autoFocus}
            className={input.class || input.className}
            complexPassword={input.complexPassword}
            default={input.default}
            description={input.description}
            errorMessage={input.errorMessage}
            form={props.name}
            fullWidth={props.fullWidth}
            handleLabel={input.handleLabel}
            hideSuccessIcon={input.hideSuccessIcon}
            hint={input.hint}
            key={input.name}
            label={input.label}
            max={input.max}
            maxFileSize={input.maxFileSize}
            maxRows={input.maxRows}
            min={input.min}
            minRows={input.minRows}
            name={input.name}
            onChange={update}
            options={input.options}
            placeholder={input.placeholder}
            readonly={input.readonly}
            required={input.required}
            rows={input.rows}
            text={input.text}
            title={input.title}
            type={input.type}
            url={input.url}
            valid={input.valid}
            value={input.value}
            wrapperClassName={input.wrapperClassName}
            submitForm={submit}
          />
        );
      })}

      {props.buttonText &&
        <Button
          color={props.destructive ? 'red' : 'green'}
          loading={props.loading || loading}
          // text={}
          action={submit}
          className={`${Style.button} ${props.buttonProps?.className || ''}`}
          fullWidth={fullWidth && !props.cancel}
          disabled={props.disabled}
          {...props.buttonProps}
        >{props.buttonText}</Button>
      }

      {props.cancel &&
        <Button
          color={props.destructive ? 'green' : 'red'}
          outline
          text='Cancel'
          className={Style.button}
          action={props.cancel}
          {...props.buttonProps}
        />
      }
    </form>
  );
}

Form.propTypes = {
  buttonProps: shape(Button.propTypes),
  buttonText: oneOfType([string, object]),
  cancel: bool,
  destructive: bool,
  disabled: bool,
  focusInput: string,
  fullWidth: bool,
  inputs: object,
  loading: bool,
  name: string.isRequired,
  onSubmit: func,
  resetOnSubmit: bool,
  url: string,
  method: string,
  callback: func,
}

function PaymentForm(props) {

  const stripe = useStripe();
  const elements = useElements();

  return <Form {...props} stripe={stripe} elements={elements} />

}

export { Form, PaymentForm };
