const Style = {

  input: `relative w-6 h-6 cursor-pointer mr-2 bg-none 
  border border-solid border-slate-200 bg-center appearance-none
  checked:bg-[url('components/form/checkbox/icons/ico-check-white.svg')] 
  checked:bg-emerald-500 checked:bg-[length:10px] p-1`,
  label: 'cursor-pointer mt-[2px] normal-case',
  wrapper: 'flex flex-row items-start justify-start w-full'

}

export default Style;