/***
*
*   AUTH LAYOUT
*   Layout for the signup/signin pages
*
**********/

import { HomeNav, Footer, ClassHelper } from 'components/lib';
import Style from './auth.tailwind.js';

export function AuthLayout(props) {

  return (
    <main className={ClassHelper(Style)}>
      <HomeNav />
      <div className={Style.content}>
        {<props.children {...props.data} />}
      </div>
      <Footer />
    </main>
  );
}