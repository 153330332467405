import {
  Animate,
  BotsNav,
  Button,
  Card,
  Form,
  Grid, Heading,
  Loader,
  ViewContext,
  useAPI
} from 'components/lib';
import { Fragment, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBotsTitle } from './hooks/useBotsTitle';

export function BotsEdit(props) {

  const { id: botId } = useParams();
  const { data: bot, loading } = useAPI(`/api/bot/${botId}`);
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const { setTitle } = useBotsTitle();


  function deleteBot(data, callback) {

    if (!bot) return;

    context.modal.show({
      title: 'Delete Bot',
      form: {},
      buttonText: 'Delete Bot',
      text: `Are you sure you want to delete ${bot?.name}? This cannot be undone.`,
      url: `/api/bot/${bot?.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(bot?.name + ' was deleted', 'success', true);
      navigate('/bots');
      if (callback)
        callback();

    });
  }


  return (

    <Fragment>

      <BotsNav />

      {loading
        ? <Loader />
        : <Animate>
          <Grid cols={2}>
            <Card
              title="Edit Bot"
            >

              <Form
                disabled={!bot}
                inputs={{
                  name: {
                    label: 'Name',
                    type: 'text',
                    required: true,
                    value: bot?.name,
                    autofocus: true,
                    hint: 'What should we call your bot?',
                    errorMessage: 'Please name your bot'
                  },
                  description: {
                    label: 'Description',
                    type: 'textarea',
                    required: true,
                    value: bot?.description,
                    hint: 'What does your bot answer questions about?',
                    errorMessage: 'Please add a description for your bot'
                  },
                }}
                url={`/api/bot/${bot?.id}`}
                method={'PATCH'}
                buttonText={'Save Bot'}
                fullWidth={true}
                callback={res => {
                  const updatedBot = res?.data?.data;
                  setTitle(updatedBot?.name);
                }}
              />
            </Card>
          </Grid>
          <Grid cols={2}>
            <Card transparent>
              <Heading>Delete Bot</Heading>
              <Button outlineRed fullWidth={false} action={deleteBot} disabled={!bot}>Delete Bot</Button>
            </Card>
          </Grid>
        </Animate>
      }
    </Fragment>

  );
}
