const ButtonStyle = {

  base: 'cursor-pointer font-sans rounded-md py-3 px-7 transition-colors duration-300 ease-out hover:ease-out',
  btn: 'block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500 text-white',
  big: 'md:text-lg',
  small: 'text-sm px-7',
  outline: '!text-slate-500 border-solid border border-slate-200  hover:border-slate-300 hover:bg-slate-200 bg-transparent',
  white: 'bg-white',
  green: 'bg-emerald-500 hover:bg-emerald-600 text-white',
  red: 'bg-red-500 hover:bg-red-600 hover:border-red-500',
  outlineRed: '!text-red-500 border-solid border-3 border border-red-300 hover:!text-white hover:border-red-500 hover:bg-red-500 bg-transparent',
  blue: 'bg-blue-500 hover:bg-blue-600 hover:border-blue-600',
  orange: 'bg-orange-500 hover:bg-orange-600 hover:border-orange-600',
  textOnly: 'text-slate-500',
  iconButton: 'inline-block p-0 w-4 h-4',
  iconText: 'pl-12',
  iconTextOnly: 'pl-6',
  fullWidth: 'w-full',
  rounded: 'rounded-full',
  relative: 'relative',
  absolute: 'absolute',
  alignLeft: 'pl-6 pr-0',
  alignRight: 'pl-0 pr-6',
  loading: `relative after:absolute after:w-3 after:h-3 after:right-2 after:top-[1.125rem] after:z-10 
    after:bg-[url('components/button/icons/ico-loader.svg')] after:bg-contain  after:origin-center after:animate-loading
    after:!text-red-200 after:fill-red-500`,
  loadingColor: `after:bg-[url('components/button/icons/ico-loader-color.svg')]`,
  disabled: 'opacity-50 cursor-not-allowed',

}

export default ButtonStyle;